import React, { useState } from "react";
import { BsCloudUpload } from "react-icons/bs";
import InputMask from "react-text-mask";

import NavMenu from "../NavMenu";
import Footer from "../Footer";
import Cta from "./CtaSend";
import sicredi from "../../images/sicredi.svg";
import sol from "../../images/sol.png";

import { Container, Content } from "./styles";

const InputPhone = (props) => {
  function mask(userInput) {
    const numbers = userInput.match(/\d/g);
    let numberLength = 0;
    if (numbers) {
      numberLength = numbers.join("").length;
    }

    if (numberLength > 10) {
      return [
        "(",
        /[1-9]/,
        /[1-9]/,
        ")",
        " ",
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        "-",
        /\d/,
        /\d/,
        /\d/,
        /\d/,
      ];
    }
    return [
      "(",
      /[1-9]/,
      /[1-9]/,
      ")",
      " ",
      /\d/,
      /\d/,
      /\d/,
      /\d/,
      "-",
      /\d/,
      /\d/,
      /\d/,
      /\d/,
    ];
  }

  return <InputMask guide mask={mask} {...props} />;
};

const OrcamentoPage = () => {
  const [filename, setFileName] = useState("");

  const handleFile = (e) => {
    if (!e.target.files[0]) {
      setFileName("");
      return;
    }

    setFileName(e.target.files[0].name);
  };

  return (
    <>
      <NavMenu />
      <Container>
        <h1>Orçamento</h1>
        <hr />

        <h2>Solicite um orçamento gratuito e sem compromisso</h2>
        <Content>
          <form
            action="https://getform.io/f/d6259f4c-49c9-4bf3-bc12-ef40e8201533"
            method="post"
            encType="multipart/form-data"
          >
            <label htmlFor="name">
              Nome Completo
              <input name="name" id="name" required />
            </label>
            <label htmlFor="email">
              E-mail
              <input type="email" name="email" id="email" required />
            </label>
            <label htmlFor="phone">
              Telefone com DDD
              <InputPhone name="phone" id="phone" required />
            </label>

            <label htmlFor="city">
              Cidade
              <input name="cidade" id="city" required />
            </label>
            <label htmlFor="uf">
              Estado
              <input name="uf" id="uf" required />
            </label>
            <label htmlFor="values">
              Valor médio gasto mensalmente com energia em R$
              <input name="gasto-medio" id="values" required />
            </label>
            <input
              type="hidden"
              name="acesso-a-fatura"
              value="https://getform.io/forms/20109"
            />
            <label htmlFor="fatura">
              Anexe a imagem de uma fatura recente (opcional)
              <div>
                <BsCloudUpload color={"#999"} size={40} />
                <span>click para escolher imagem</span>
              </div>
              <input
                style={{ display: "none" }}
                type="file"
                name="file"
                id="fatura"
                onChange={(e) => handleFile(e)}
              />
              {filename && (
                <div style={{ background: "#ffe671", padding: 10 }}>
                  <strong>ARQUIVO SELECIONADO:</strong>
                  <span>{filename}</span>
                </div>
              )}
            </label>
            <input
              type="hidden"
              name="redirectTo"
              value="https://www.sfsolar.com.br"
            />
            {!filename && (
              <p>
                <b>ATENÇÃO:</b> Para que possamos dimensionar adequadamente o
                seu sistema é importante que tenhamos acesso a sua fatura da
                conta de energia, por isso pedimos que anexe a imagem de uma
                fatura recente.
              </p>
            )}

            <Cta type="submit">Enviar Solicitação</Cta>
          </form>
          <article>
            <p>
              Aceitamos diversas formas de pagamento e possuímos parceria direta
              com o Sicredi para realização de financiamento com{" "}
              <strong>taxa de juros de até 0.89% a.m.</strong> em 48 vezes.
              Realize um orçamento gratuito ou entre em contato conosco para
              mais informações.
            </p>
            <span>
              <img src={sicredi} alt="Sicredi" title="Sicredi" />
              <img src={sol} alt="SF Solar" title="SF SOLAR" />
            </span>
          </article>
        </Content>
      </Container>
      <Footer />
    </>
  );
};

export default OrcamentoPage;
