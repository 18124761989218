import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  max-width: 1120px;
  margin: 0 auto;
  margin-top: 72px;
  margin-bottom: 100px;
  padding: 0 32px;

  h1 {
    font-size: 46px;
    font-weight: bold;
    text-align: center;

    @media (max-width: 885px) {
      font-size: 36px;
    }

    @media (max-width: 400px) {
      font-size: 28px;
    }
  }

  hr {
    margin: 0 auto;
    margin-top: 24px;
    width: 140px;
    height: 3px;
    background: #e66000;
    border: 0;
  }

  h2 {
    font-size: 32px;
    font-weight: bold;
    margin: 64px 0 32px 0;
    text-align: center;

    @media (max-width: 885px) {
      font-size: 28px;
    }

    @media (max-width: 450px) {
      font-size: 22px;
      margin-bottom: 28px;
    }
  }

  form {
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    margin-top: 16px;
    label {
      width: 100%;
      display: flex;
      align-items: flex-start;
      justify-content: center;
      flex-direction: column;
      margin-bottom: 22px;

      font-size: 16px;
      font-weight: bold;

      input {
        width: 100%;
        display: flex;
        height: 38px;
        padding: 6px 8px;
        font-size: 16px;
        margin-top: 4px;

        background: #fff;
        color: #555;
        border-radius: 8px;
        border: 2px solid #d1d5da;
        transition: all 0.2s ease-in-out;

        :focus {
          border-color: #e66000;
          box-shadow: inset 0 1px 2px rgba(27, 31, 35, 0.075),
            0 0 0 0.2em rgba(230, 96, 0, 0.3);
          transition: all 0.2s ease-in-out;
        }
      }

      div {
        width: 100%;
        margin-top: 4px;
        margin-bottom: 8px;
        border-radius: 8px;
        border: 2px dotted #d1d5da;
        padding: 16px 0;

        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        cursor: pointer;

        span {
          color: #888;
        }
      }
    }
    p {
      font-size: 13px;
      color: #666;
      margin-bottom: 24px;
    }
  }
`;

export const Content = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  form {
    width: 60%;
  }

  @media (max-width: 830px) {
    flex-direction: column;

    form {
      width: 100%;
    }
  }

  article {
    width: 40%;
    display: flex;
    align-self: flex-start;
    margin: 35px 0 0 64px;
    flex-direction: column;

    @media (max-width: 830px) {
      width: 100%;
      align-self: center;
      margin: 0;
      margin-top: 54px;

      padding: 32px;
    }

    p {
      font-size: 26px;
      line-height: 36px;

      @media (max-width: 500px) {
        font-size: 20px;
        line-height: 30px;
      }

      @media (max-width: 410px) {
        font-size: 14px;
        line-height: 24px;
      }
    }

    span {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 40px;
      padding: 0 20px;

      img:nth-last-of-type(1) {
        max-width: 50px;
      }
      img:nth-last-of-type(2) {
        max-width: 300px;
        margin-right: 32px;
      }
    }
  }
`;
