import React from "react";

import SEO from "../components/seo";
import Layout from "../components/Layout";
import OrcamentoPage from "../components/Orcamento";

const Orcamento = () => {
  return (
    <Layout>
      <SEO title="Contato " />
      <OrcamentoPage />
    </Layout>
  );
};

export default Orcamento;
